<template>
	<div class="maxBox" :class="!isMobile ? 'pcbox' : ''">
		<h1>환불 정책</h1>
		<div class="textDes">
			<p>저희는 모든 사용자님들위해 최고의 제품 체험 및 서비스를 온힘을 다해 제공하겠습니다.</p>
			<br>
			<br>
			<p>구매하시기 전, 제품 상세 상황과 주의 상황을 자세하게 읽어주시기를 바랍니다, 본 제품은 가상제품이며 절차에따라 교환을 해주시기를 바랍니다.</p>
			<p>사용자님께서 구매 성공하신 후 
			</p><p>해당 게임에서 교환을 해주시기를 바랍니다. 또한 저희의 kakao(GM33333)로 연락해주셔도 됩니다.</p>
			<br>
			<br>
			<p>1. 본인의 이유로 반품 혹은 교환하고 싶은 경우 
			</p><p>저희의 상품은 가상 제품이기때문에, 오로지 한번만 사용이 가능합니다. 그래서 쿠폰은 받은 이후, 사용 여부와 상관없이 반품 혹은 교환이 불가능합니다.</p>
			<br>
			<br>
			<p>2.제품의 원인으로 반품 혹은 교환하고 싶은 경우
			</p><p>1.쿠폰은 받지 못한 경우: 이메일 hongdenglonghk0330@gmail.com 으로 연락하셔서 재발급 메일을 신청하시면,저희는 근무일 3일내에 재발급 해드립니다.
			</p><p>2. 쿠폰을 받았지만,정상적으로 사용하지 못하는 상황: 활성화 할 수 없는 캡쳐를 제공하여,hongdenglonghk0330@gmail.com 이메일로 보내주시면 재발급이 가능하며,근무일 3일내에 재발급해드립니다.</p> 
			<br>
			<br>
			<br>
			<p>저희를 연락해주세요 
			</p><p>만약 사용자님께서 다른 문제 혹은 의혹이 있을경우, 이메일: hongdenglonghk0330@gmail.com 혹은 전화번호 :010-9263-7079 으로 연락해주시면 감사합니다.
			</p>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed } from 'vue'
	import useConfigStore from '@/store/modules/config'
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
</script>
<style lang="less">
  @import '@/assets/styles/common';
</style>
<style lang="less" scoped>
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
</style>
